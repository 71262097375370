import React from 'react'
import { useStyletron } from '../../theme'
import { primitives } from '../../theme/primitives'

import './Spinner.css'

interface ISpinner {
    /** Size variant for this spinner */
    size?: 'lg' | 'sm' | 'md'
    /** Color scheme to use for this spinner */
    theme?: keyof typeof primitives
}

/** Indeterminate spinner to indicate a loading state */
const Spinner = ({ theme: colorTheme, size }: ISpinner) => {
    const [css, theme] = useStyletron()

    return (
        <div className={`loader ${theme ? 'text-' + theme : ''} ${size ? 'loader-size-' + size : ''}`}>
            <div
                className={`${css({
                    border: `.25rem solid ${
                        theme.colors[(`${colorTheme}200` as keyof typeof primitives) || 'primary200']
                    }`
                })} loader-background`}
            />
            <div
                className={`${css({
                    borderLeft: `.25rem solid ${theme.colors[colorTheme || 'primary']}`,
                    borderBottom: `.25rem solid transparent`,
                    borderTop: `.25rem solid transparent`,
                    borderRight: `.25rem solid transparent`
                })} loader-foreground`}
            />
        </div>
    )
}

export default Spinner
