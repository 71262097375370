import { ResultCode } from '@adyen/adyen-web/dist/types/components/types'
import { useApolloClient } from '@apollo/client'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND, BUTTON_SIZE } from 'parkdepot-shared/components/Buttons/types'
import { CarpayApplyDiscountCodeMutation, DiscountType, FullOrderFragment, LotType } from 'parkdepot-shared/gql/graphql'
import { CARPAY_STATUS } from 'parkdepot-shared/models/database'
import { useStyletron } from 'parkdepot-shared/theme'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { OrderFragment } from '../../graphql/queries/carpayGetOrders.query'
import getDerivedValuesFromOrder from '../../helpers/getDerivedValuesFromOrder'
import usePolicyContextMapper from '../../hooks/usePolicyContextMapper'
import useTariffMapper from '../../hooks/useTariffMapper'
import useUserInputStore from '../../hooks/useUserInputStore'
import { StayOrLeaveDecision, useOrdersStore } from '../../views/Orders/ordersListingContext'
import useRedeemVoucherCode from '../../views/Orders/useRedeemVoucherCode'
import AddVoucherModal from '../AddVoucherModal'
import useGraphQLErrorHandler from '../GraphQLErrorHandler'

interface VoucherProps {
    order: FullOrderFragment
}

const Voucher = ({ order }: VoucherProps) => {
    const history = useHistory()
    const {
        applied_discount: appliedDiscount,
        order_id: orderId,
        discount_types: discountTypes,
        lot_type
    } = getDerivedValuesFromOrder({ order })
    const { plate, attachDiscount, couponCode, orderIdToApplyCode, stayDecision } = useOrdersStore()
    const selectedPolicyContextUUID = useUserInputStore((state) => state.getPolicyId())
    const selectedPolicy = useUserInputStore((state) => state.policy)
    const selectedTariff = useUserInputStore((state) => state.tariff)
    const policies = usePolicyContextMapper(orderId)
    const tariffs = useTariffMapper(orderId)
    const [voucherValue, setVoucherValue] = useState(couponCode ?? '')
    const [errorMessage, setErrorMessage] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { t } = useTranslation(['orders'])
    const [, theme] = useStyletron()
    const client = useApolloClient()

    const handleDiscountAppliedSuccess = useCallback(
        (data: CarpayApplyDiscountCodeMutation) => {
            const {
                status,
                applied_discount: requestAppliedDiscount,
                applied_discount_codes: appliedDiscountCondes,
                price
            } = data.carpay_apply_discount_code
            const isFree = (price || 0) - (requestAppliedDiscount || 0) <= 0

            if (status === 'valid' && isFree) {
                const order = client.cache.readFragment({
                    id: client.cache.identify({ __typename: 'Order', order_id: orderId }),
                    fragment: OrderFragment
                })

                const modifiedOrder = { ...order, order_status: CARPAY_STATUS.PAID }

                client.writeFragment({
                    id: client.cache.identify({ __typename: 'Order', order_id: orderId }),
                    fragment: OrderFragment,
                    data: modifiedOrder
                })

                history.replace(`/orders/success?order=${orderId}&plate=${plate}`, {
                    success: 'Authorised' as ResultCode
                })
            }

            if (status === 'valid') {
                setVoucherValue('')
                setErrorMessage('')
                setIsModalOpen(false)
                attachDiscount(orderId, requestAppliedDiscount || 0, appliedDiscountCondes || [])
            } else if (status === 'invalid') {
                setErrorMessage(t('orders.modal.invalidCode'))
            } else if (status === 'used') {
                setErrorMessage(t('orders.modal.usedCode'))
            }
        },
        [history, plate, orderId, t, attachDiscount, client]
    )

    const { handleGraphQLError } = useGraphQLErrorHandler()
    const [redeemVoucherCode, { loading }] = useRedeemVoucherCode(handleDiscountAppliedSuccess, handleGraphQLError)

    // All this crazy logic is to trigger modal automatically under certain circumstances.
    // I proposed to change the designs and change this behaviour but didn't have luck
    const havePoliciesNoneSelected = policies.length > 1 && !selectedPolicy
    const dontHavePolicies = policies.length <= 1 && !selectedPolicy
    const haveTariffsNoneSelected = tariffs.length > 0 && !selectedTariff
    const dontHaveTariffs = lot_type === LotType.Prepaid && tariffs.length === 0
    const isPostpaidOrHybrid = lot_type === LotType.Postpaid || lot_type === LotType.Hybrid
    const wantToStay = stayDecision === StayOrLeaveDecision.notSelected || stayDecision === StayOrLeaveDecision.stay
    const couldApplyDiscount =
        (!havePoliciesNoneSelected || dontHavePolicies) &&
        ((!haveTariffsNoneSelected && wantToStay) || dontHaveTariffs || isPostpaidOrHybrid || !wantToStay) &&
        !appliedDiscount

    useEffect(() => {
        if (!couponCode) return // no coupon code -> nothing to apply
        if (orderIdToApplyCode !== orderId) return // coupon does not belong to this order -> nothing to apply
        if (!couldApplyDiscount) return // cannot apply discount yet -> nothing to apply

        setIsModalOpen(true)
        setVoucherValue(couponCode)
        redeemVoucherCode({
            code: couponCode,
            order_id: orderId,
            parking_context_uuid: selectedPolicyContextUUID
        })
    }, [couponCode, orderIdToApplyCode, selectedPolicyContextUUID, orderId, redeemVoucherCode, couldApplyDiscount])

    const handleVoucherModalSubmit = () => {
        if (!voucherValue) return

        redeemVoucherCode({
            code: voucherValue,
            order_id: orderId,
            parking_context_uuid: selectedPolicyContextUUID
        })
    }

    const handleVoucherChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setVoucherValue(e.currentTarget.value)
    }

    const handleModalClose = () => {
        setIsModalOpen(false)
    }

    const voucherEnabled =
        discountTypes.includes(DiscountType.StringInput) || discountTypes.includes(DiscountType.Universal)
    const voucherButtonVisible = voucherEnabled && !appliedDiscount && couldApplyDiscount

    return (
        <>
            {voucherButtonVisible && (
                <Button
                    data-testid="applyCodeButton"
                    onClick={(e) => {
                        e.preventDefault()

                        setIsModalOpen(true)
                    }}
                    $kind={BUTTON_KIND.secondary}
                    $size={BUTTON_SIZE.large}
                    overrides={{
                        StartEnhancer: {
                            style: { fontSize: theme.sizing.scale800 }
                        }
                    }}
                    startEnhancer={<i className="pkd-discount" />}
                >
                    {t('orders.orderCard.addVoucherCode')}
                </Button>
            )}

            <AddVoucherModal
                onVoucherChange={handleVoucherChange}
                isOpen={isModalOpen}
                errorMessage={errorMessage}
                inputValue={voucherValue}
                loading={loading}
                onClose={handleModalClose}
                onSubmit={handleVoucherModalSubmit}
            />
        </>
    )
}

export default Voucher
